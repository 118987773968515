import "./Gazifikacia.css";
import doc from "./Инструкция_газ.pdf";
import doc1 from "./protocol_gaz.pdf";
import img from "../assets/plan.png";
import { useEffect } from "react";

export default function Gazifikacia() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="gaz">
      <div>
        <h2 className="gaz__title">
          ДОГАЗИФИКАЦИЯ ЗАРЕГИСТРИРОВАННЫХ ЖИЛЫХ ДОМОВ В СНТ
        </h2>
        <p>
          <span>
            По итогам проведенного Внеочередного общего собрания членов СНТ
            «РАДАР» 22.06.2024 г. необходимая информация передана в
            Администрацию Зеленоградского городского округа и Правительство
            Калининградской области.{" "}
          </span>
        </p>
        <p>
          <span>
            Всего официально зарегистрированных жилых строений в СНТ «РАДАР» 30
            шт., не зарегистрированных, но построенных жилых домов 13 шт., один
            садовый дом (не подлежит догазификации). <br /> Не
            зарегистрированные жилые дома не подлежат догазификации!
          </span>
        </p>
        <span>&#xa0;</span>
        <span>
          Всем садоводам, имеющим зарегистрированный жилой дом, необходимо до
          01.08.2024 года направить заявку на догазификацию своего домовладения.
        </span>
        <p>Инструкция по подаче заявки в электронном виде:</p>
        <p>
          <a
            href={doc}
            target="_blank"
            rel="noreferrer"
            className="link_to_doc"
          >
            Открыть инструкцию
          </a>
        </p>
        <span>
          Ситуационный план (возможно использовать кадастровую карту, на которой
          необходимо выделить свой земельный участок и сделать скрин-шот):
          <img src={img} alt="" className="gaz__img"/>
        </span>
        <span>
          Планируемая величина максимального часового расхода указываем не более
          «7», иначе придется прикреплять расчет.{" "}
        </span>
        <span>
          Протокол Внеочередного общего собрания членов СНТ «РАДАР» от
          22.06.2024 г.:
          <p>
            <a
              href={doc1}
              target="_blank"
              rel="noreferrer"
              className="link_to_doc"
            >
              Посмотреть протокол
            </a>
          </p>
        </span>
        <span>&#xa0;</span>
        <span>Второй вариант подачи заявки: направление через МФЦ: </span>
        <span>При себе необходимо иметь следующие документы: </span>
        <li>Паспорт</li>
        <li>СНИЛС</li>
        <li>
          Правоустанавливающие документы на домовладение и земельный участок
        </li>
        <li>
          Расчёт максимального расхода газа в час — если он превышает 7 куб. м/ч
        </li>
        <li>
          Ситуационный план расположения земельного участка в границах
          населённого пункта. Вы можете самостоятельно распечатать эскиз
          земельного участка 
          <a href="https://pkk.rosreestr.ru/" target="_blank" style={{marginLeft: "3px"}}>
             с публичной кадастровой карты
          </a>
        </li>
        <li>
          <span>Кадастровый номер земельного участка</span>
        </li>
        <li>
          <span>
            Протокол собрания членов СНТ (с заявками на копию протокола в
            бумажном виде обращаться лично к Председателю Колотилину Д.В.)
          </span>
        </li>
        <p>Также возможен вариант подачи через Госуслуги. </p>
      </div>
    </section>
  );
}
